export function mirrorClassIcon(classes?: Array<string>):string {
    if(!classes) return "";
    let classIconPath: string;
    if(classes.includes("2") && classes.includes("4")){
        classIconPath = "/static/img/icons/png/Klasse_II_IV.png";
    } else if (classes.includes("2") && classes.includes("3")) {
        classIconPath = "/static/img/icons/png/Klasse_II.png";
        return `<img class="pim_icon" src="${classIconPath}" /></td><td class="pim_icon_cell"><img class="pim_icon" src="/static/img/icons/png/Klasse_III.png" />`;
    } else if (classes.includes("2")) {
        classIconPath = "/static/img/icons/png/Klasse_II.png";
    } else if (classes.includes("3")) {
        classIconPath = "/static/img/icons/png/Klasse_III.png";
    } else if (classes.includes("4")) {
        classIconPath = "/static/img/icons/png/Klasse_IV.png";
    } else if (classes.includes("5")) {
        classIconPath = "/static/img/icons/png/Klasse_V.png";
    } else if (classes.includes("6")) {
        classIconPath = "/static/img/icons/png/Klasse_VI.png";
    }
    if(!classIconPath) return "";
    return `<img class="pim_icon" src="${classIconPath}" />`;
}

export function typeIcon(type?: string, mountingSide?: string): string {
    if(!type) return "";
    let classIconPath: string;
    switch(type){
        case 'Komplettspiegel':
            classIconPath = "/static/img/icons/png/Komplettspiegel.png";
            break;
        case 'Spiegelkopf ohne Glaseinheit':
            classIconPath = "/static/img/icons/png/Spiegelkopf_ohne_Glaseinheit.png";
            break;
        case 'Komplettsystem Klasse II+IV in':
        case 'Komplettsystem Klasse II+IV inkl. Spiegelstange':
            classIconPath = "/static/img/icons/png/Komplettspiegel.png";
            break;
        case 'Busspiegel mit Gelenkarm':
            classIconPath = "/static/img/icons/png/Busspiegel_mit_Gelenkarm.png";
            break;
        case 'Busspiegel, hängend':
            classIconPath = "/static/img/icons/png/Busspiegel_haengend.png";
            break;
        case 'Busspiegel, stehend':
            classIconPath = "/static/img/icons/png/Busspiegel_stehend.png";
            break;
        case 'Glaseinheit':
            classIconPath = "/static/img/icons/png/Glaseinheit.png";
            break;
        case 'Ersatzglas':
            classIconPath = "/static/img/icons/png/Ersatzglas.png";
            break;
        case 'Heizfolie':
            classIconPath = "/static/img/icons/png/Heizfolie.png";
            break;
        case 'Rahmen':
            classIconPath = "/static/img/icons/png/Rahmen.png";
            break;
        case 'Abdeckung':
            classIconPath = "/static/img/icons/png/Abdeckung.png";
            break;
        case 'Haltelasche':
            classIconPath = "/static/img/icons/png/Haltelasche.png";
            break;
        case 'ReduzierhÃ¼lse':
            classIconPath = "/static/img/icons/png/reduzierhuelse_neu.png";
            break;
        case 'Schraube':
            classIconPath = "/static/img/icons/png/Schraube.png";
            break;
        case 'Kugelstange':
            classIconPath = "/static/img/icons/png/Kugelstange.png";
            break;
        case 'Spiegelhalter':
            classIconPath = "/static/img/icons/png/Spiegelhalter_1.png";
            break;
        case 'Stange':
            if(!mountingSide|| mountingSide == "links und rechts") {
                classIconPath = "/static/img/icons/png/Stange_links_rechts.png";
            } else if (mountingSide == "links") {
                classIconPath = "/static/img/icons/png/Stange_links.png";
            } else if (mountingSide == "rechts") {
                classIconPath = "/static/img/icons/png/Stange_rechts.png";
            } else {
                //TODO: Icon for all other bracket types
                classIconPath = "/static/img/icons/png/Stange_rechts.png";
            }
            break;
        case 'Scharnier':
            classIconPath = "/static/img/icons/png/scharnier.png";
            break;
        case 'Halteblock':
            classIconPath = "/static/img/icons/png/Halteblock.png";
            break;
        case 'Adapter':
            classIconPath = "/static/img/icons/png/Adapter.png";
            break;
        case 'Aufsteckkeil':
            classIconPath = "/static/img/icons/png/Aufsteckkeil.png";
            break;
        case 'Aufsteckkeil mit Direktkontakt':
            classIconPath = "/static/img/icons/png/Aufsteckkeil_Direktkontakt.png";
            break;
        case 'Faltenbalg':
            classIconPath = "/static/img/icons/png/Faltenbalg.png";
            break;
        case 'Heizkabel, 2-polig':
            classIconPath = "/static/img/icons/png/Heizkabel_rund.png";
            break;
        case 'Steuerkabel':
            classIconPath = "/static/img/icons/png/Steuerkabel.png";
            break;
        case 'Versorgungsleitung':
            classIconPath = "/static/img/icons/png/Versorgungsleitung.png";
            break;
        case 'Spiegelverstellschalter':
            classIconPath = "/static/img/icons/png/Spiegelverstellschalter.png";
            break;
        case 'kompletter Kabelsatz':
            classIconPath = "/static/img/icons/png/Kabelsatz.png";
            break;
        case 'Getriebe':
            classIconPath = "/static/img/icons/png/Getriebe.png";
            break;
        case 'Blinker':
            classIconPath = "/static/img/icons/png/Blinker.png";
            break;
        case 'Gluehbirne':
            classIconPath = "/static/img/icons/png/Gluehbirne.png";
            break;
        default:
            break;
    }
    if(!classIconPath) return "";
    return `<img class="pim_icon" title="Typ: ${type}" alt="Typ: ${type}" src="${classIconPath}" />`
}

export function mountingSideIcon (mountingSide?: string): string {
    if(!mountingSide) return "";
    let classIconPath: string;
    switch (mountingSide.toLowerCase()) {
        case 'links und rechts':
            classIconPath = "/static/img/icons/png/links_rechts.png";
            break;
        case 'links':
            classIconPath = "/static/img/icons/png/links.png";
            break;
        case 'rechts':
            classIconPath = "/static/img/icons/png/rechts.png";
            break;
        case 're rhd':
            classIconPath = "/static/img/icons/png/rechts_einsetzbar_RHD.png";
            break;
        case 're lhd':
            classIconPath = "/static/img/icons/png/rechts_einsetzbar_LHD.png";
            break;
        case 'li lhd':
            classIconPath = "/static/img/icons/png/links_einsetzbar_LHD.png";
            break;
        case 'li rhd':
            classIconPath = "/static/img/icons/png/links_einsetzbar_RHD.png";
            break;
        case 'li rhd/re lhd (beifahrerseite)':
        case 'universal':
            classIconPath = "/static/img/icons/png/links_rechts.png";
            break;
        default:
            break;
    }
    if(!classIconPath) return "";
    return `<img class="pim_icon" title="Anbauseite: ${mountingSide}" alt="Anbauseite: ${mountingSide}" src="${classIconPath}" />`
}

export function driverSideIcon (driverSide?: string): string {
    if(!driverSide) return "";
    let classIconPath: string;
    switch (driverSide.toLowerCase()) {
        case 'links und rechts':
            classIconPath = "/static/img/icons/png/LHD_RHD.png";
            break;
        case 'links':
            classIconPath = "/static/img/icons/png/LHD.png";
            break;
        case 'rechts':
            classIconPath = "/static/img/icons/png/RHD.png";
            break;    
        default:
            break;
    }
    if(!classIconPath) return "";
    return `<img class="pim_icon" title="Fahrerseite: ${driverSide}" alt="Fahrerseite: ${driverSide}" src="${classIconPath}" />`
}

export function voltageIcon (voltage?: string): string {
    if(!voltage) return "";
    let classIconPath: string;
    switch (voltage.toUpperCase()) {
        case '12 V':
            classIconPath = "/static/img/icons/png/12V.png";
            break;
        case '24 V':
            classIconPath = "/static/img/icons/png/24V.png";
            break;
        case '12 V/ 24 V':
            classIconPath = "/static/img/icons/png/12u24V.png";
            break;  
        default:
            break;
    }
    if(!classIconPath) return "";
    return `<img class="pim_icon" title="Betriebsspannung: ${voltage.toUpperCase()}" alt="Betriebsspannung: ${voltage.toUpperCase()}" src="${classIconPath}" />`
}

export function curvatureIcon (curvature?: string): string{
    if(!curvature) return "";
    const classIconPath = curvature.replace(/\s/g, "").replace("/", "-");
    return `<img class="pim_icon" title="Wölbungsradius: ${curvature}" alt="Wölbungsradius: ${curvature}" src="/static/img/icons/png/WR_${classIconPath}.png" />`
}