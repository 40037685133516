import { MirrorItem, MirrorListItem } from "./interfaces/mirrorItem";
import { Vehicle } from "./interfaces/vehicle";
import { ArticleNumber } from "./interfaces/articleNumber";
import { mirrorClassIcon, typeIcon, mountingSideIcon, driverSideIcon, voltageIcon, curvatureIcon } from "./iconChooser";
import  Choices from "choices.js"
class MEKRAQueryLib {

    private static instance: MEKRAQueryLib;
    private baseUrl: string;
    private productList: HTMLElement;
    private fullListOffset: number = 0;
    private noResultsBox: HTMLDivElement;
    private isLoading: boolean = false;
    private selectedManufacturers: Array<string> = [];
    private selectedVehicleNames: Array<string> = [];
    private manufacturerSelect: HTMLSelectElement;
    private vehicleSelect: HTMLSelectElement;
    private articleNumberBox: HTMLInputElement;
    private partFamilySelect: HTMLSelectElement;
    private vehicleList: Array<Vehicle>;
    private manufacturerList: Array<string>;
    private typedaheadNumbers: Array<any> = [];
    private loader: HTMLDivElement;
    private loaderOverlay: HTMLDivElement;
    private vehicleChoices;
    private manufacturerChoices;
    private partFamilyChoices;
    private abortFetch;
    private loadMoreButton: HTMLButtonElement;
    private articlebox_outer: HTMLDivElement;
    private focusedArticelnumberItem: number;

    static debounce = (func, delay) => {
        let inDebounce
        return function () {
            const context = this
            const args = arguments
            clearTimeout(inDebounce)
            inDebounce = setTimeout(async () => func.apply(context, args), delay)
        }
    }

    private constructor(baseUrl: string = "ajax") {
        let searchURL = new URL(window.location.href);
        this.selectedManufacturers = searchURL.searchParams.get("manufacturer") ? searchURL.searchParams.get("manufacturer").split(";") : [];
        this.selectedVehicleNames = searchURL.searchParams.get("vehicle") ? searchURL.searchParams.get("vehicle").split(";") : [];
        let varURL = new URL(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + baseUrl);
        this.baseUrl = varURL.href;
    }

    static getInstance() {
        if (!MEKRAQueryLib.instance) {
            MEKRAQueryLib.instance = new MEKRAQueryLib();
        }
        return MEKRAQueryLib.instance;
    }

    public init() {
        this.productList = document.getElementById("product-list");
        this.manufacturerSelect = document.querySelector<HTMLSelectElement>('select[name="manufacturer[]"]');
        this.vehicleSelect = document.querySelector<HTMLSelectElement>('select[name="vehicle-name[]"]');
        this.partFamilySelect = document.querySelector<HTMLSelectElement>('select[name="partfamily[]"]');
        this.articleNumberBox = document.querySelector<HTMLInputElement>('input[name="articlenumbers-box"]');
        this.articlebox_outer = document.querySelector<HTMLDivElement>('div[id="articlebox-list-outer"]');
        this.noResultsBox = document.querySelector(".alert-no-result-items");
        this.loader = document.querySelector<HTMLDivElement>('div[id="pim-loader-outer"]');
        this.loaderOverlay = document.querySelector<HTMLDivElement>('div[id="pim-transparent-overlay"]');
        this.registerEvents();
        this.getVehiclesList();
        this.getManufacturerList();
        this.getPartfamilys();
        this.loadMoreButton = insertAfter(this.generateLoadMoreButton(), this.productList);
    }

    public getBaseUrl(): string {
        return this.baseUrl;
    }

    public getArticleNumberBox(): HTMLInputElement {
        return this.articleNumberBox;
    }

    public getSelectedManufacturers(): Array<string> {
        return this.selectedManufacturers;
    }

    public setSelectedManufacturers(manu: Array<string>) {
        this.selectedManufacturers = manu;
    }

    public getSelectedVehicles(): Array<string> {
        return this.selectedVehicleNames;
    }

    public setSelectedVehicles(vehicles: Array<string>) {
        this.selectedVehicleNames = vehicles;
    }

    private showLoader() {
        this.loader.setAttribute("style", "display: block");
        this.loaderOverlay.setAttribute("style", "display: block");
    }

    private hideLoader() {
        this.loader.removeAttribute("style");
        this.loaderOverlay.removeAttribute("style");
    }

    private fetchData(url: string, params?: Object) {
        const contoller: AbortController = new AbortController();
        const { signal } = contoller;

        const fetchParams = {
            ...params,
            signal
        };

        const promise: Promise<any> = fetch(url, fetchParams).then(data => {
            this.hideLoader()
            return data.json()
        });
        this.showLoader();
        return [promise, contoller.abort.bind(contoller)];
    }

    public refreshOnURLChange(url: URL) {
        this.setSelectedManufacturers(url.searchParams.get("manufacturer") ? url.searchParams.get("manufacturer").split(";") : []);
        this.setSelectedVehicles(url.searchParams.get("vehicle") ? url.searchParams.get("vehicle").split(";") : []);
        const currentQuery = url.searchParams.get("query");
        if (currentQuery) {
            this.getFilteredList(null, null, currentQuery.split(";"));
            this.getArticleNumberBox().value = currentQuery;
        } else if (this.getSelectedVehicles().length >> 0 || this.getSelectedManufacturers().length >> 0) {
            this.getFilteredList(this.getSelectedManufacturers(), this.getSelectedVehicles())
        } else {
            this.getPartialList(10);
        }
        this.manufacturerChoices.removeActiveItems();
        this.vehicleChoices.removeActiveItems();
        if (this.selectedManufacturers.length >> 0 && this.selectedVehicleNames.length === 0) this.vehicleChoices.setChoices(this.vehicleList.filter(e => this.selectedManufacturers.includes(e.manufacturer)), 'vehicle', 'vehicle', true);
        this.selectedManufacturers.forEach(manu => {
            this.manufacturerChoices.setChoiceByValue(manu);
        })
        this.selectedVehicleNames.forEach(vehicle => {
            this.vehicleChoices.setChoiceByValue(vehicle);
        });
    }

    private clearElement(element: HTMLElement) {
        element.childNodes.forEach(child => {
            if (child instanceof HTMLElement) child.setAttribute("style", "opacity: 0");
            setTimeout(function () { element.removeChild(child); }, 200);
        })
    }

    private addSearchParam(key: string, value: Array<string>) {
        let currentURL = new URL(window.location.href);
        (value.length << 1) ? currentURL.searchParams.set(key, value.join(";")) : currentURL.searchParams.delete(key);
        window.history.pushState(null, document.title, currentURL.href);
    }

    private setSearchParam(url: URL) {
        let currentURL = new URL(window.location.href);
        currentURL.search = url.search;
        window.history.pushState(null, document.title, currentURL.href);
    }

    private registerEvents() {
        this.manufacturerSelect.addEventListener('change', (event) => {
            const selected: Array<string> = Array.apply(null, (<HTMLSelectElement>event.target).options).map(e => e.value);
            this.selectedManufacturers = selected;
            if (Object.entries(selected).length > 0) {
                this.getFilteredList(this.selectedManufacturers, this.selectedVehicleNames);
                if (this.selectedVehicleNames.length === 0) this.vehicleChoices.setChoices(this.vehicleList.filter(e => selected.includes(e.manufacturer)), 'vehicle', 'vehicle', true);
            } else if (this.selectedVehicleNames.length >> 0) {
                this.vehicleChoices.setChoices(this.vehicleList, 'vehicle', 'vehicle', true);
                this.getFilteredList(this.selectedManufacturers, this.selectedVehicleNames);
            } else {
                this.getPartialList(10);
                this.vehicleChoices.setChoices(this.vehicleList, 'vehicle', 'vehicle', true);
                this.clearElement(this.productList);
            }
        });

        this.vehicleSelect.addEventListener('change', (event) => {
            const selected: Array<string> = Array.apply(null, (<HTMLSelectElement>event.target).options).map(e => e.value);
            this.selectedVehicleNames = selected;
            if (Object.entries(selected).length > 0) {
                this.getFilteredList(this.selectedManufacturers, this.selectedVehicleNames);
            } else if (this.selectedManufacturers.length >> 0) {
                this.getFilteredList(this.selectedManufacturers, this.selectedVehicleNames);
            } else {
                this.getPartialList(10);
                this.clearElement(this.productList);
            }
        });

        this.partFamilySelect.addEventListener('change', (event) => {
            window.location.href = "/de/produkte/datenbank/" + (<HTMLSelectElement>event.target).value;
        });

        this.articleNumberBox.addEventListener('focus', (event) => {
            this.articlebox_outer.classList.add("display_block");
        })
        this.articleNumberBox.addEventListener('blur', (event) => {
            this.articlebox_outer.classList.remove("display_block");

        })

        this.articleNumberBox.addEventListener('keyup', MEKRAQueryLib.debounce((event) => {
            // prevent UP and DOWN keys since they are handled elsewhere
            if (event.keyCode == 40 || event.keyCode == 38) return;
            // retrieve the datalist element
            let articleboxList = document.getElementById('articlebox_list');
            // minimum number of characters before we start to generate suggestions
            const min_characters = 3;

            if (this.articleNumberBox.value.length < min_characters) {
                this.articlebox_outer.classList.remove("display_block");
                this.clearElement(articleboxList);
                return;
            } else {
                this.typeahead(this.articleNumberBox.value).then((numbers) => {
                    this.typedaheadNumbers = numbers;
                    this.clearElement(articleboxList);
                    this.articlebox_outer.classList.add("display_block");
                    this.typedaheadNumbers.forEach(number => {
                        // Create a new <option> element.
                        var option = document.createElement('li');
                        option.dataset["pim_id"] = number.pim_id.toString();
                        option.dataset["articleNumber"] = number.articleNumber;
                        option.dataset["partFamily"] = number.partFamily;
                        option.innerHTML = number.articleNumber + " : " + (number.type? number.type : "");
                        option.classList.add("articlenumber_list_item")
                        option.addEventListener('mousedown', (event) => {
                            event.preventDefault();
                        })
                        option.addEventListener("click", (event) => {
                            this.articleNumberBox.blur();
                            window.location.href = `/de/produkte/datenbank/${(<HTMLLIElement>event.target).dataset["partFamily"]}/${(<HTMLLIElement>event.target).dataset["articleNumber"]}`;
                        })
                        // attach the option to the datalist element
                        articleboxList.appendChild(option);
                    })
                    this.focusedArticelnumberItem = -1;
                }).catch(error => { });
            }
        }, 500));

        this.articleNumberBox.addEventListener('keydown', (event) => {
            let UL = document.getElementById('articlebox_list');
            if (!UL) return;
            const articleboxList = Array.from(UL.getElementsByTagName("li"));
            if (event.keyCode == 40) {
                event.preventDefault();
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                this.focusedArticelnumberItem++;
                /*and and make the current item more visible:*/
                this.addActiveToArticelList(articleboxList);
            } else if (event.keyCode == 38) { //up
                event.preventDefault();
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                this.focusedArticelnumberItem--;
                /*and and make the current item more visible:*/
                this.addActiveToArticelList(articleboxList);
            } else if (event.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                event.preventDefault();
                if (this.focusedArticelnumberItem > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (articleboxList) {
                        articleboxList[this.focusedArticelnumberItem].click();
                        this.removeActiveFromArticleList(articleboxList);
                    }
                } else {
                    this.getFilteredList(null, null, this.articleNumberBox.value.split(" "))
                }
            }
        });
    }

    private addActiveToArticelList(articleBoxList: HTMLLIElement[]) {
        /*a function to classify an item as "active":*/
        if (!articleBoxList) return false;
        /*start by removing the "active" class on all items:*/
        this.removeActiveFromArticleList(articleBoxList);
        if (this.focusedArticelnumberItem >= articleBoxList.length) this.focusedArticelnumberItem = 0;
        if (this.focusedArticelnumberItem < 0) this.focusedArticelnumberItem = (articleBoxList.length - 1);
        /*add class "autocomplete-active":*/
        articleBoxList[this.focusedArticelnumberItem].classList.add("autocomplete-active");
    }

    private removeActiveFromArticleList(x: HTMLLIElement[]) {
        /*a function to remove the "active" class from all autocomplete items:*/
        x.forEach(elem => {
            elem.classList.remove("autocomplete-active");
        })
    }

    private getVehiclesList() {
        fetch(this.baseUrl + '/vehicles')
            .then(response => {
                return response.json();
            })
            .then(data => {
                this.vehicleList = data;
                this.initVehicleFields(this.vehicleSelect)
            })
            .catch(error => {
                console.error(error);
            });
    }

    private getManufacturerList() {
        fetch(this.baseUrl + '/manufacturers')
            .then(response => {
                return response.json();
            })
            .then(data => {
                this.manufacturerList = data;
                this.initManufacturerFields(this.manufacturerSelect)
            })
            .catch(error => {
                console.error(error);
            });
    }

    private getPartfamilys() {
        fetch(this.baseUrl + '/partfamily')
            .then(response => {
                return response.json();
            })
            .then(data => {
                this.initPartfamilyFields(this.partFamilySelect, data)
            })
            .catch(error => {
                console.error(error);
            });
    }

    private typeahead(subString?: string): Promise<Array<ArticleNumber>> {
        if (!subString) return new Promise((resolve) => { resolve([]) });
        return fetch(this.baseUrl + `/typeahead?query=${subString}`)
            .then(response => {
                return response.json();
            })
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error(error);
            });
    }

    private initVehicleFields(select: HTMLSelectElement) {
        var choicesOptions = {
            noResultsText: select.dataset['noresults'],
            noChoicesText: select.dataset['nochoices'],
            itemSelectText: select.dataset['placeholder'],
            placeholder: true,
            placeholderValue: select.dataset['placeholder'],
            removeItemButton: true
        };

        this.vehicleChoices = new Choices(select, choicesOptions);
        this.vehicleChoices.setChoices(this.vehicleList, 'vehicle', 'vehicle', true);
        this.selectedVehicleNames.forEach(vehicle => {
            this.vehicleChoices.setChoiceByValue(vehicle);
        });
        if (this.selectedManufacturers.length >> 0 && this.selectedVehicleNames.length == 0) this.vehicleChoices.setChoices(this.vehicleList.filter(e => this.selectedManufacturers.includes(e.manufacturer)), 'vehicle', 'vehicle', true);
    };

    private initManufacturerFields(select: HTMLSelectElement) {
        var choicesOptions = {
            noResultsText: select.dataset['noresults'],
            noChoicesText: select.dataset['nochoices'],
            itemSelectText: select.dataset['placeholder'],
            placeholder: true,
            placeholderValue: select.dataset['placeholder'],
            removeItemButton: true
        };
        this.manufacturerChoices = new Choices(select, choicesOptions);
        this.manufacturerChoices.setChoices(this.manufacturerList, 'manufacturer', 'manufacturer', true);
        this.selectedManufacturers.forEach(manu => {
            this.manufacturerChoices.setChoiceByValue(manu);
        })
    };

    private initPartfamilyFields(select: HTMLSelectElement, data) {
        var choicesOptions = {
            noResultsText: select.dataset['noresults'],
            noChoicesText: select.dataset['nochoices'],
            itemSelectText: select.dataset['placeholder'],
            placeholder: true,
            placeholderValue: select.dataset['placeholder'],
            removeItemButton: true,
            shouldSort: false
        };
        this.partFamilyChoices = new Choices(select, choicesOptions);
        this.partFamilyChoices.setChoices(data, 'partfamily', 'partfamily', true);
    };

    /**
     * getList
     */
    public getFullList() {
        if (this.isLoading) return;
        const filterUrl = new URL(this.baseUrl);
        filterUrl.pathname = filterUrl.pathname + "/list";
        this.setSearchParam(filterUrl);
        filterUrl.searchParams.set("parentId", "5245");
        fetch(filterUrl.href)
            .then(response => {
                this.isLoading = false;
                return response.json();
            })
            .then(data => {
                data.forEach(product => {
                    let partFamilyNode = this.generateMirrorListing(<MirrorItem>product);
                    let listFragment = document.createElement('div');
                    listFragment.setAttribute('class', 'list-item');
                    listFragment.appendChild(partFamilyNode);
                    this.productList.appendChild(listFragment);
                    this.getMirrorsForFamily(product.pim_id, product.articleNumber, listFragment);
                });
            })
            .catch(error => {
                console.error(error);
            });
        this.isLoading = true;
    }

    public loadMore(): void {
        this.getPartialList(10, this.fullListOffset);
    }

    public getFilteredList(manufacturers?: Array<string>, vehicles?: Array<string>, query?: Array<string>) {
        if (this.abortFetch) this.abortFetch();
        const filterUrl = new URL(this.baseUrl);
        filterUrl.pathname = filterUrl.pathname + "/filter";
        if (manufacturers && manufacturers.length >> 0) filterUrl.searchParams.set("manufacturer", manufacturers.join(";"));
        if (vehicles && vehicles.length >> 0) filterUrl.searchParams.set("vehicle", vehicles.join(";"));
        if (query && query.length >> 0) filterUrl.searchParams.set("query", query.join(";"));
        this.setSearchParam(filterUrl);
        let promise: Promise<any>;
        [promise, this.abortFetch] = this.fetchData(filterUrl.href);
        promise.then(data => {
            this.toggleLoadMoreButton(false);
            this.clearElement(this.productList);
            if (data.error) {
                this.noResultsBox.setAttribute("style", "display: block");
                return;
            } else {
                this.noResultsBox.removeAttribute("style");
            }

            data.forEach(product => {
                let partFamilyNode = this.generateMirrorListing(<MirrorItem>product);
                let listFragment = document.createElement('div');
                listFragment.setAttribute('class', 'list-item');
                listFragment.appendChild(partFamilyNode);
                this.productList.appendChild(listFragment);
            });
        })
            .catch(error => {
                if (error.name === 'AbortError') return;
                console.error(error);
            });
    }

    public getPartialList(limit?: number, offset?: number) {
        if (this.isLoading) return;
        if (this.abortFetch) this.abortFetch();
        if (!limit) this.fullListOffset = 0;
        const filterUrl = new URL(this.baseUrl);
        filterUrl.pathname = filterUrl.pathname + "/list";
        this.setSearchParam(filterUrl);
        filterUrl.searchParams.set("parentId", "5245");
        filterUrl.searchParams.set("limit", limit ? limit.toString() : "0");
        filterUrl.searchParams.set("offset", offset ? offset.toString() : "0");
        let promise: Promise<any>;
        [promise, this.abortFetch] = this.fetchData(filterUrl.href)
        promise.then(data => {
                this.isLoading = false;
                this.toggleLoadMoreButton(true);
                this.fullListOffset = (offset ? offset : 0) + limit;
                data.forEach(product => {
                    let partFamilyNode = this.generateMirrorListing(<MirrorItem>product);
                    let listFragment = document.createElement('div');
                    listFragment.setAttribute('class', 'list-item');
                    listFragment.appendChild(partFamilyNode);
                    this.productList.appendChild(listFragment);
                    this.getMirrorsForFamily(product.pim_id, product.articleNumber, listFragment);
                });
            })
            .catch(error => {
                console.error(error);
            });
        this.isLoading = true;
    }

    private getMirrorsForFamily(pim_id: string, family: string, listFragment: HTMLElement) {
        fetch(this.baseUrl + `/list?parentId=${pim_id}&listStyle=true`)
            .then(response => {
                return response.json();
            })
            .then(data => {
                let table = document.createElement('table');
                table.setAttribute('class', 'pim_table');
                data.forEach(product => {
                    table.appendChild(this.generateListStyleFragment(<MirrorListItem>product, family));
                });
                let mDiv = document.createElement('div');
                mDiv.setAttribute('class', 'pim_row__articleList');
                mDiv.appendChild(table);

                // `<li class="list-item" data-vehicle-name="${mirror.linkedVehicles.map(e => e.vehicle).join(",")}"> </li>`

                listFragment.appendChild(mDiv);
            })
            .catch(error => {
                console.error(error);
            });
    }

    private generateMirrorListing(mirror: MirrorItem): DocumentFragment {
        let range = document.createRange();
        range.selectNode(this.productList);
        return range.createContextualFragment(`
            <div class="row pim_row" data-product-uid="${mirror.pim_id}">
            <a href="/de/produkte/datenbank/${mirror.articleNumber}"><h2>${mirror.articleNumber ? mirror.articleNumber : ''} ${mirror.description ? mirror.description : ''}</h2></a>
                <div class="row">
                        <div class="pim_row__familyImage col-sm-12 col-md-2">${mirror.imgCode ? mirror.imgCode : ''}</div>
                        <div class="col-sm-12 col-md-10 pim_row__familyInfo">
                            <div class="col-md-12">
                                <p><b>Beschreibung:</b> ${mirror.description ? mirror.description : ''} </p>
                            </div>
                            <div class="col-sm-12 col-md-6" style="padding-top: 5px; padding-bottom:5px;">${mirror.material ? "Material: " + mirror.material : ''}</div>
                            <div class="col-sm-12 col-md-6" style="padding-top: 5px; padding-bottom:5px;">${mirror.color ? "Farbe: " + mirror.color : ''}</div>
                            <div class="col-sm-12 col-md-6" style="padding-top: 5px; padding-bottom:5px;">${mirror.bracket ? "Stangenaufnahme: " + mirror.bracket : ''}</div>
                            <div class="col-sm-12 col-md-6" style="padding-top: 5px; padding-bottom:5px;">${mirror.homologationNumbers ? "Homologationsnummer: " + mirror.homologationNumbers.toString() : ''}</div>
                            <div class="col-sm-12 col-md-6" style="padding-top: 5px; padding-bottom:5px;">${mirror.mirrorClass ? "Spiegelklasse: " + mirror.mirrorClass : ''}</div>
                            <div class="col-sm-12 col-md-6" style="padding-top: 5px; padding-bottom:5px;">${mirror.linkedVehicles ? "Fahrzeuge: " + mirror.linkedVehicles.map(e => e.vehicle).join(",") : ''}</div>
                        </div>
                </div>
            </div>`);
    }

    private generateListStyleFragment(mirror: MirrorListItem, family): DocumentFragment {
        const frag = document.createDocumentFragment();
        const template = document.createElement("tr");
        template.classList.add("pim_row__rowItem");
        template.dataset["artikelnummer"] = mirror.articleNumber;
        template.innerHTML = `
                    <td><a href="/de/produkte/datenbank/${family}/${mirror.articleNumber}">${mirror.articleNumber}</a></td>
                    <td class="pim_icon_cell">${mirrorClassIcon(mirror.mirrorClass)}</td>
                    <td class="pim_icon_cell">${typeIcon(mirror.type, mirror.mountingSide)}</td>
                    <td class="pim_icon_cell">${mountingSideIcon(mirror.mountingSide)}</td>
                    <td class="pim_icon_cell">${driverSideIcon(mirror.driverSide)}</td>
                    <td class="pim_icon_cell">${voltageIcon(mirror.voltage)}</td>
                    <td class="pim_icon_cell">${curvatureIcon(mirror.curvature)}</td>
                    <td>${mirror.dimensions}</td>
                    <td class="pim_row__lastCol">
                        <a href="/de/produkte/datenbank/${family}/${mirror.articleNumber}" class="pim_row__detailsButton">
                        <i class="fa fa-info" style="margin-right:2px;"></i> Details</a>                 
                    </td>`;
        frag.appendChild(template);
        return  frag;

    }

    private generateLoadMoreButton(): HTMLButtonElement {
        let button: HTMLButtonElement = document.createElement('button');
        button.setAttribute("class", "btn btn-primary pim_load_more");
        button.innerText = "Mehr Laden";
        button.addEventListener("click", () => { this.loadMore() });
        return button;
    }

    private toggleLoadMoreButton(visible: boolean) {
        visible ? this.loadMoreButton.style.display = 'block' : this.loadMoreButton.style.display = 'none'
    }
}

function addEvent(obj, ev, fn) {
    if (obj.addEventListener) obj.addEventListener(ev, fn, false);
    else if (obj.attachEvent) obj.attachEvent("on" + ev, fn);
}

function insertAfter(newNode, referenceNode) {
    return referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

let queryLib: MEKRAQueryLib = MEKRAQueryLib.getInstance();
if (document.readyState === "complete") {
    queryLib.init();
        if (queryLib.getSelectedVehicles().length >> 0 || queryLib.getSelectedManufacturers().length >> 0) {
            queryLib.getFilteredList(queryLib.getSelectedManufacturers(), queryLib.getSelectedVehicles())
        } else {
            queryLib.getPartialList(10);
        }
        window.onpopstate = function () {
            const currentURL = new URL(window.location.href);
            queryLib.refreshOnURLChange(currentURL);
        };
} else {
    addEvent(document, "DOMContentLoaded", () => {
        queryLib.init();
        if (queryLib.getSelectedVehicles().length >> 0 || queryLib.getSelectedManufacturers().length >> 0) {
            queryLib.getFilteredList(queryLib.getSelectedManufacturers(), queryLib.getSelectedVehicles())
        } else {
            queryLib.getPartialList(10);
        }
        window.onpopstate = function () {
            const currentURL = new URL(window.location.href);
            queryLib.refreshOnURLChange(currentURL);
        };
    });
}